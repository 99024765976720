<template>
  <v-chart class="chart" :option="option" />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart, BarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  ToolboxComponent,
  GridComponent,
  MarkLineComponent,
  MarkPointComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { max } from "zrender/lib/core/vector";

use([
  CanvasRenderer,
  LineChart,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  ToolboxComponent,
  GridComponent,
  MarkLineComponent,
  MarkPointComponent,
]);

export default {
  props: [],
  components: {
    VChart,
  },
  provide: {
    // [THEME_KEY]: "dark"
  },
  data() {
    return {
      option: {
        tooltip: {
          trigger: "axis",
        },
        legend: {},
        toolbox: {
          show: true,
        }, 
        xAxis: {
          type: "category",
          data: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"],
          axisLabel: {
                        color: "white"
                    }
        },
        yAxis: {
          type: "value",
          axisLabel: {
                        color: "white"
                    }
        },
        series: [
          {
            data: [150, 230, 224, 218, 135, 147, 260, 150, 230, 183, 300, 133, 255, 241, 301, 127, 273, 122, 145, 165, 200, 150, 230, 224, 218, 135, 147, 260, 150, 230],
            type: "line",
            smooth: true,
            color: "#E57373",
          },
          {
            data: [24, 80, 95, 120, 280, 140, 167, 123, 80, 95, 120, 280, 140, 167, 123, 135, 147, 260, 150, 230, 183,  127, 273, 230, 224, 218, 150, 230, 244, 287],
            type: "line",
            smooth: true,
            color: "lightgreen",
          },
          
          {
            data: [180],
            type: "line",
            markLine: {
              lineStyle: {
                type: "dashed",
                color: "#E6EE9C",
              },
              data: [
                {
                  type: "average",
                  name: "Avg",
                  label: {
                    position: "end",
                    formatter: "Total Cost",
                  },
                },
              ],
            },
          },
        ],
      },
    };
  },
};
</script>

<style scoped>
.chart {
  height: 50vh;
}
</style>

